const frequentQuestions = [
  {
    id: 1,
    question: 'questions.questionOne.question',
    answer: 'questions.questionOne.answer',
  },
  {
    id: 2,
    question: 'questions.questionTwo.question',
    answer: 'questions.questionTwo.answer',
  },
  {
    id: 3,
    question: 'questions.questionThree.question',
    answer: 'questions.questionThree.answer',
  },
  {
    id: 4,
    question: 'questions.questionFour.question',
    answer: 'questions.questionFour.answer',
  },
  {
    id: 5,
    question: 'questions.questionFive.question',
    answer: 'questions.questionFive.answer',
  },
  {
    id: 6,
    question: 'questions.questionSix.question',
    answer: 'questions.questionSix.answer',
  },
];

export default frequentQuestions;
