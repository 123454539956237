import React from 'react';
import BottomSection from './components/VerticalCard/BottomSection/BottomSection';
import MainSlider from './components/MainSlider';
import ObdProcessCard from './components/ObdProcessCard';
import AboutUs from './components/AboutUs';
import Solutions from './components/Solutions';
import BlueBanner from '../../components/BlueBanner';
import Form from '../contact-us/components/Form/Form';
import FrequentQuestions from './components/FrequentQuestions';
import { useTranslation } from 'react-i18next';
import './styles.css';

const Home = () => {
  const { t } = useTranslation(['blueBanner']);
  return (
    <>
      <MainSlider />
      <ObdProcessCard id="obd" />
      <BottomSection />
      <div id="industries" style={{ marginTop: '150px', paddingTop: '100px' }}>
        <BlueBanner title={t('option1.title')} buttonLabel={t('option1.button')} />
      </div>
      <Solutions />
      <AboutUs id="aboutUs" title={t('news.title')} />
      <Form id="contactUs" />
      <FrequentQuestions id="faq" />
    </>
  );
};

export default Home;
