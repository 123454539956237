import PropTypes from 'prop-types';
import CardController from '../VerticalCard/CardController';
import './index.css';
import { useTranslation } from 'react-i18next';

const SwiperSlide = ({ src, index, actual }) => {
  return (
    <div className={actual === index ? 'mySlides fade' : 'mySlides hidden'}>
      <img alt={src} src={`assets/img/${src}`} />
    </div>
  );
};

const MainSlider = () => {
  const { t } = useTranslation(['solutions', 'home']);

  const SLIDES = [
    {
      url: '/solutions/1',
      src: 'mujer.jpg',
      title: t('digitalOnboarding.title'),
      subtitle: t('digitalOnboarding.subtitle'),
    },
  ];

  return (
    <div id="main-swiper">
      <div className="slideshow-container">
        {SLIDES.map((item, index) => (
          <SwiperSlide key={String(index)} actual={0} index={index} src={item.src} />
        ))}
      </div>
      <CardController />
    </div>
  );
};

SwiperSlide.propTypes = {
  src: PropTypes.string,
  index: PropTypes.number,
  actual: PropTypes.number,
};

export default MainSlider;
