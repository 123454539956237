import React from 'react';
import PropTypes from 'prop-types';

const LittleCard = ({ title, description, id, visible }) => {
  return (
    <div
      className={visible ? 'little-card-container animated' : 'little-card-container'}
      id={`${id}-little-card`}
    >
      <img width="75px" src={`assets/img/${id}.svg`} />
      <span className="little-card-title">{title}</span>
      <span className="little-card-description">{description}</span>
    </div>
  );
};

LittleCard.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  visible: PropTypes.bool,
};

LittleCard.defaultProps = {
  visible: false,
};

export default LittleCard;
