import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import './styles.css';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import IconButton from '@material-ui/core/IconButton';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import { frequentQuestions } from 'data';

const FrequentQuestions = ({ id: idSection }) => {
  const { t } = useTranslation(['frequentQuestions']);

  return (
    <>
      <div className="fq-footer" />
      <div id={idSection} className="fq-container">
        <div className="fq-title-container">
          <span className="fq-title">{t('title')}</span>
        </div>
        <div className="fq-accordion-wrapper">
          {frequentQuestions.map(({ id, question, answer }) => (
            <div className="fq-accordion-container" key={id}>
              <Accordion className="fq-accordion">
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography className="fq-question">{t(question)}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography className="fq-answer">
                    {t(answer)}
                    {id === 3 && (
                      <a href="#contactUs">
                        <IconButton>
                          <OpenInNewIcon />
                        </IconButton>
                      </a>
                    )}
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

FrequentQuestions.propTypes = {
  id: PropTypes.string.isRequired,
};

export default FrequentQuestions;
