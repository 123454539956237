import React, { useState } from 'react';
import { ThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { MenuItem, Select } from '@material-ui/core';
import './styles.css';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';

const theme = createMuiTheme({
  overrides: {
    MuiInputBase: {
      root: {
        fontFamily: 'Livvic',
        color: 'white',
        fontWeight: 500,
      },
    },
    MuiInput: {
      root: {
        border: '1px solid white',
        borderRadius: '50px',
        padding: '0px 10px',
      },
      underline: {
        '&:before': {
          borderBottom: '0px',
        },
        '&:after': {
          borderBottom: '0px',
        },
        '&:hover:not($disabled):not($focused):not($error):before': {
          borderBottom: '0px',
        },
      },
    },
    MuiSelect: {
      select: {
        '&:focus': {
          backgroundColor: 'transparent',
        },
      },
      icon: {
        color: 'white',
      },
    },
  },
});

const SelectLanguage = () => {
  const { i18n } = useTranslation();
  const [language, setLanguage] = useState(i18next.language || 'es');

  const handleChangeLanguage = (event) => {
    setLanguage(event.target.value);
    i18n.changeLanguage(event.target.value);
  };

  return (
    <ThemeProvider theme={theme}>
      <Select value={language} onChange={handleChangeLanguage}>
        <MenuItem value={'en'}>
          <div className="navbar-language-container">
            <img width="15" src="assets/img/english.svg" />
            <span className="navbar-span-language">EN</span>
          </div>
        </MenuItem>
        <MenuItem value={'es'}>
          <div className="navbar-language-container">
            <img width="15" src="assets/img/spanish.svg" />
            <span className="navbar-span-language">ES</span>
          </div>
        </MenuItem>
      </Select>
    </ThemeProvider>
  );
};

export default SelectLanguage;
