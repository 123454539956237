import React from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Captcha from 'react-numeric-captcha';
import { send } from 'emailjs-com';
import emailjs from '../../../../../../emailkey';
import './styles.css';
import '../../../../../contact-us/components/Form/captcha.css';

const INITIAL_FORM = {
  name: '',
  email: '',
  phone: '',
  company: '',
  message: '',
  checked: false,
};

const FormReduced = () => {
  const { t } = useTranslation(['contactUs', 'rest']);

  const [form, setForm] = useState(INITIAL_FORM);
  const [isCaptchaOk, setIsCaptchaOk] = useState(false);

  const handleSend = async () => {
    let isFormIncomplete = false;
    for (var key in form) {
      if (!form[key] && key !== 'checked') {
        isFormIncomplete = true;
        break;
      }
    }
    if (isFormIncomplete) return alert(t('rest:words.completeForm'));
    if (!form.checked) return alert(t('rest:words.acceptContactForm'));
    if (!isCaptchaOk) return alert(t('rest:words.completeCaptcha'));
    setForm(INITIAL_FORM);
    try {
      await send(emailjs.SERVICE_ID, emailjs.TEMPLATE_ID, form, emailjs.USER_ID);
      alert(t('rest:words.thanksContactForm'));
    } catch (error) {
      alert(t('rest:words.errorContactForm'));
    }
  };
  return (
    <div>
      <div className={`fr-container`}>
        <div id="fr-card-container">
          <div className="fr-card-container">
            <div>
              <div className="fr-input-row-container">
                <div className="fr-input-container">
                  <span className="fr-input-label">{t('name')}</span>
                  <input
                    maxLength={25}
                    value={form.name}
                    onChange={(e) => setForm({ ...form, name: e.target.value })}
                    className="fr-input"
                    type="text"
                  />
                </div>
                <div className="fr-input-container">
                  <span className="fr-input-label">{t('email')}</span>
                  <input
                    maxLength={50}
                    value={form.email}
                    onChange={(e) => setForm({ ...form, email: e.target.value })}
                    className="fr-input"
                    type="text"
                  />
                </div>
              </div>
              <div className="fr-input-row-container">
                <div className="fr-input-container">
                  <span className="fr-input-label">{t('phone')}</span>
                  <input
                    maxLength={25}
                    value={form.phone}
                    onChange={(e) => setForm({ ...form, phone: e.target.value })}
                    className="fr-input"
                    type="text"
                  />
                </div>
                <div className="fr-input-container">
                  <span className="fr-input-label">{t('company')}</span>
                  <input
                    maxLength={25}
                    value={form.company}
                    onChange={(e) => setForm({ ...form, company: e.target.value })}
                    className="fr-input"
                    type="text"
                  />
                </div>
              </div>
              <div className="fr-text-area-row-container">
                <div className="fr-text-area-container">
                  <span className="fr-input-label">{`${t('message')} ${
                    form.message.length
                  }/220`}</span>
                  <textarea
                    maxLength={220}
                    value={form.message}
                    onChange={(e) => setForm({ ...form, message: e.target.value })}
                    className="fr-text-area"
                    rows="6"
                  />
                </div>
              </div>
              <div className="fr-checkbox-container">
                <input
                  type="checkbox"
                  checked={form.checked}
                  onChange={(e) => setForm({ ...form, checked: e.target.checked })}
                />
                <span className="fr-checkbox-label">{t('checkboxLabel')}</span>
              </div>
              <div className="fr-captcha-container">
                <Captcha
                  onChange={(status) => setIsCaptchaOk(status)}
                  className="captcha"
                  placeholder={t('rest:words.captchaPlaceholder')}
                />
              </div>
              <div className="fr-checkbox-container">
                <button id="fr-button" onClick={handleSend}>
                  <a>{t('send')}</a>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FormReduced;
