import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Divider from '@material-ui/core/Divider';
import { industries } from 'data';
import './styles.css';

const Industries = ({ name }) => {
  const { t } = useTranslation(['industries']);
  const [{ icon }] = industries.filter((item) => item.name === name);

  return (
    <>
      <div className="ss-industry">
        <div className="ss-ind-img">
          {name === 'insurance' || name === 'events' ? (
            <img src={`assets/img/${icon}.png`} style={{ width: '32px' }} />
          ) : (
            <img src={`assets/img/${icon}.svg`} />
          )}
        </div>
        {t(name)}
      </div>
      <Divider />
    </>
  );
};

Industries.propTypes = {
  name: PropTypes.string,
};
export default Industries;
