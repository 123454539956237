import React from 'react';
import PropTypes from 'prop-types';
import ClearIcon from '@material-ui/icons/Clear';
import './styles.css';
// import { useHistory } from 'react-router-dom';
import SelectLanguage from './SelectLanguage';
import { useTranslation } from 'react-i18next';

const Menu = ({ handleCloseMenu }) => {
  const { t } = useTranslation(['rest']);
  // const history = useHistory();

  // const handleLink = (url) => {
  //   history.push(url);
  //   window.scrollTo(0, 0);
  // };

  return (
    <div className="navbar-menu-mobile-container">
      <ClearIcon className="navbar-close-icon" fontSize="large" onClick={handleCloseMenu} />
      <div onClick={handleCloseMenu} className="navbar-link-container">
        <a className="navbar-link navbar-link-mobile" href="#aboutUs">
          {t('words.aboutUs')}
        </a>
        <a className="navbar-link navbar-link-mobile" href="#industries">
          {t('words.industries').toUpperCase()}
        </a>
        <a className="navbar-link navbar-link-mobile" href="#contactUs">
          {t('words.contactUs')}
        </a>
        <a className="navbar-link navbar-link-mobile" href="#faq">
          {t('words.FAQ')}
        </a>
        <SelectLanguage />
      </div>
    </div>
  );
};

export default Menu;

Menu.propTypes = {
  handleCloseMenu: PropTypes.func.isRequired,
};
