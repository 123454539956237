import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTranslation } from 'react-i18next';
import Industries from './components/Industries';
// import Solutions from 'pages/about-us/components/Solutions';
import BlueBanner from 'components/BlueBanner';
import { useParams } from 'react-router-dom';
import { solutions } from 'data';
import './styles.css';

const SingleSolution = () => {
  const isTabletLg = useMediaQuery((theme) => theme.breakpoints.up('md'));
  const { t } = useTranslation(['solutions', 'blueBanner', 'rest']);
  let { id } = useParams();
  const [{ title, subTitle, description, image, video }] = solutions.filter(
    (solution) => solution.id === parseInt(id)
  );
  const industry = ['bank', 'health', 'transport', 'insurance', 'events'];

  return (
    <div id="single-solution">
      <div id="ss-cover-container">
        <img src={image} />
      </div>
      <div id="ss-content">
        <div id="ss-info-container">
          <div id="ss-title">{t(title)}</div>
          <div id="ss-subtitle">{t(subTitle)}</div>
          <div id="ss-description-title">{t('rest:words.description')}</div>
          <div id="ss-description">{t(description)}</div>
        </div>
        <div id="ss-industries">
          <div id="ss-ind-title">{t('rest:words.industries')}</div>
          {industry.map((item, index) => (
            <Industries key={String(index)} name={item} />
          ))}
        </div>
      </div>

      <div id="ss-blue-border">
        <video
          id="ss-video"
          width={isTabletLg ? '996' : '100%'}
          height={isTabletLg ? '600' : '385px'}
          controls
        >
          <source src={video} type="video/mp4" />
        </video>
      </div>
      {/* <Solutions /> */}
      <BlueBanner
        title={t('blueBanner:option2.title')}
        buttonLabel={t('blueBanner:option2.button')}
        titleStyle={{ fontSize: '32px', maxWidth: '400px' }}
      />
    </div>
  );
};

export default SingleSolution;
