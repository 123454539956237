import { useRef } from 'react';
import useIntersectionObserver from 'hooks/useIntersectionObserver';
import Slider from './components/Slider';
// import SideContent from './components/SideContent';
import './styles.css';
import { useTranslation } from 'react-i18next';

const Solutions = () => {
  const ref = useRef(null);
  const visible = useIntersectionObserver(ref);
  // const { t } = useTranslation(['solutions', 'rest']);
  const { t } = useTranslation(['industries']);

  // const cards = [
  //   {
  //     url: '/solutions/2',
  //     src: 'microAnalizerIcon',
  //     title: t('microAnalyzer.title'),
  //     description: t('microAnalyzer.subtitle'),
  //   },
  //   {
  //     url: '/solutions/3',
  //     src: 'covidIcon',
  //     title: t('covid19.title'),
  //     description: t('covid19.subtitle'),
  //   },
  //   { url: '/solutions/4', src: 'd24Icon', title: t('d24.title'), description: t('d24.subtitle') },
  //   {
  //     url: '/solutions/1',
  //     src: 'onBoardingIcon',
  //     title: t('digitalOnboarding.title'),
  //     description: t('digitalOnboarding.subtitle'),
  //   },
  //   {
  //     url: '/solutions/5',
  //     src: 'crowdControlIcon',
  //     title: t('crowdControl.title'),
  //     description: t('crowdControl.subtitle'),
  //   },
  // ];

  const cards = [
    {
      url: '/solutions/1',
      src: 'bank',
      title: t('bank'),
      description: '',
    },
    {
      url: '/solutions/1',
      src: 'health',
      title: t('health'),
      description: '',
    },
    {
      url: '/solutions/1',
      src: 'transport',
      title: t('transport'),
      description: '',
    },
    {
      url: '/solutions/1',
      src: 'insurance',
      title: t('insurance'),
      description: '',
    },
    {
      url: '/solutions/1',
      src: 'events',
      title: t('events'),
      description: '',
    },
  ];

  return (
    <div ref={ref} id="solutions-container">
      <Slider visible={visible} cards={cards} title={t('rest:words.applications')} />
      {/* <SideContent visible={visible} /> */}
    </div>
  );
};

export default Solutions;
