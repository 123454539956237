import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import './styles.css';

const AboutUs = ({ color, id: idSection }) => {
  const { t } = useTranslation(['rest']);

  return (
    <div id={idSection}>
      <div id="au-container" className={`auc-${color}`}>
        <h3 className={`au-title auc-${color}`}>{t('rest:words.aboutUs')}</h3>
        <div className="au-ts-text-description-container-blue" style={{ marginBottom: '100px' }}>
          <p>{t('aboutUs:top.descriptionFirst')}</p>
          <p>
            <span id="au-ts-text-description-p2-highlight-blue">
              {t('aboutUs:top.descriptionHigh')}
            </span>
            <br />
            <span>{t('aboutUs:top.descriptionLast')}</span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;

AboutUs.propTypes = {
  color: PropTypes.string,
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

AboutUs.defaultProps = {
  color: 'dark',
};
