import FormReduced from './FormReduced/FormReduced';
import useWindowSize from 'hooks/useWindowsSize';
import { useTranslation } from 'react-i18next';
import './styles.css';

const CardController = () => {
  const { t } = useTranslation(['blueBanner', 'solutions']);

  const size = useWindowSize();
  const isMobile = () => size.width <= 550;

  return (
    <div id="main-card-container">
      {isMobile() ? (
        <div className="main-card-titles">
          <h3 className="main-card-title">{t('solutions:digitalOnboarding.title')}</h3>
          <p className="main-card-content">{t('solutions:digitalOnboarding.subtitle')}</p>
          <button className="main-card-button">
            <a href="#contactUs">{t('option1.button')}</a>
          </button>
        </div>
      ) : (
        <FormReduced />
      )}
    </div>
  );
};

export default CardController;
