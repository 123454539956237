import { Grid } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import './styles.css';

const ObdProcessCard = ({ id: idSection }) => {
  const { t } = useTranslation(['obdProcess', 'solutions', 'rest']);

  return (
    <div id={idSection} className="obdp-top-container">
      <div className="obdp-card-container">
        <Grid container spacing={3} className="obdp-title-container">
          <Grid item xs={12}>
            <span className="obdp-title">{t('title')}</span>
          </Grid>
        </Grid>
        <Grid container className="obdp-container">
          <Grid item sm={12} md={3} className="obdp-item-container">
            <img width="75px" src="assets/img/obd-card.svg" />
            <span className="obdp-item-title">{t('cards.deepLearning.title')}</span>
            <span className="obdp-item-description">{t('cards.deepLearning.description')} </span>
          </Grid>
          <Grid item xs={1} className="obdp-item-container obdp-arrow">
            <img width="31px" src="assets/img/arrow-right.svg" />
          </Grid>
          <Grid item sm={12} md={3} className="obdp-item-container">
            <img width="75px" src="assets/img/obd-selfie.svg" />
            <span className="obdp-item-title">{t('cards.imageAnalysis.title')}</span>
            <span className="obdp-item-description">{t('cards.imageAnalysis.description')}</span>
          </Grid>
          <Grid item xs={1} className="obdp-item-container obdp-arrow">
            <img width="31px" src="assets/img/arrow-right.svg" />
          </Grid>
          <Grid item sm={12} md={3} className="obdp-item-container">
            <img width="75px" src="assets/img/obd-persons.svg" />
            <span className="obdp-item-title">{t('cards.faceRecognition.title')}</span>
            <span className="obdp-item-description">{t('cards.faceRecognition.description')}</span>
          </Grid>
        </Grid>
        <div>
          <div className="au-ts-text-description-container-blue" style={{ color: '#225cd1' }}>
            <p>{t('rest:words.description')}</p>
            <p>
              <span style={{ color: '#4e4b66' }}>
                {t('solutions:digitalOnboarding.description')}
              </span>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

ObdProcessCard.propTypes = {
  id: PropTypes.string.isRequired,
};

export default ObdProcessCard;
